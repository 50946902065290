/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 16:43:41
 * @LastEditTime: 2022-09-09 11:42:33
 * @Description:
 */
import React, { ReactElement, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Button, message, Pagination, PaginationProps, Table } from 'antd';

import './CourseList.less';

import { ArticleListPaginateApi, deleteArticleApi } from '../../request/api';
import { useNavigate } from 'react-router-dom';
import { IRes } from '../../../typings/interface';

interface DataType {
    key: React.Key;
    title: JSX.Element;
    created_At: string;
    updated_At: string;
    action: JSX.Element;
}

interface IArticle {
    id: number;
    title: string;
    created_At: string;
    updated_At: string;
}

const Title = (props: { title: string }) => {
    return (
        <div className="courseTitle">
            <a href="#">{props.title}</a>
        </div>
    );
};

const ActionBtn = (props: { id: number; current: number; getArticleList: (skip: number, take: number) => void }) => {
    const navigate = useNavigate();
    const goToEdit = () => {
        navigate(`/courseedit/${props.id}`);
    };

    const onDelete = () => {
        deleteArticleApi({ id: props.id }).then((res: IRes) => {
            if (res.errCode == 0) {
                message.success(res.message);
                console.log(props.current);
                props.getArticleList(props.current, 10);
            } else {
                message.error(res.message);
            }
        });
        return;
    };

    return (
        <>
            <Button className="button" type="primary" onClick={goToEdit}>
                编辑
            </Button>
            <Button className="button" type="primary" danger onClick={onDelete}>
                删除
            </Button>
        </>
    );
};

const columns: ColumnsType<DataType> = [
    {
        title: '标题',
        dataIndex: 'title',
        width: '40%',
    },
    {
        title: '发布时间',
        dataIndex: 'created_At',
        width: '20%',
    },
    {
        title: '更新时间',
        dataIndex: 'updated_At',
        width: '20%',
    },
    {
        title: '操作',
        dataIndex: 'action',
    },
];

const CourseList: React.FC = (): ReactElement => {
    const [data, setData] = useState<DataType[]>([]);
    const [total, setTotal] = useState(1);
    const [current, setCurrent] = useState(1);

    const getArticleList = (pageNumber: number, take: number) => {
        ArticleListPaginateApi({
            skip: (pageNumber - 1) * 10,
            take,
        }).then((res: IRes) => {
            console.log(res);
            if (res.errCode === 0) {
                message.success(res.message, 1.5);
            }
            let temparr: DataType[] = [];
            res.data.result.map((item: IArticle) => {
                let obj = {
                    key: item.id,
                    title: <Title title={item.title} />,
                    created_At: item.created_At,
                    updated_At: item.updated_At,
                    action: <ActionBtn id={item.id} current={pageNumber} getArticleList={getArticleList} />,
                };
                temparr.push(obj);
            });
            setData(temparr);
            setCurrent(pageNumber);
            setTotal(res.data.size);
        });
    };

    const onPageChange: PaginationProps['onChange'] = (pageNumber: number) => {
        getArticleList(pageNumber, 10);
    };

    useEffect(() => {
        getArticleList(1, 10);
    }, []);

    return (
        <div className="articleList">
            {/* <Table columns={columns} dataSource={data} size="middle" pagination={{ hideOnSinglePage: true }} /> */}
            <Table columns={columns} dataSource={data} size="middle" pagination={false} />
            <Pagination
                className="pagination"
                showQuickJumper
                defaultCurrent={current}
                total={total}
                onChange={onPageChange}
            />
        </div>
    );
};

export default CourseList;
