/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 11:56:54
 * @LastEditTime: 2022-09-01 11:03:55
 * @Description:
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useEffect, useState } from 'react';
import { Dropdown, Layout, Menu, MenuProps, message, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './index.less';
import { useNavigate } from 'react-router-dom';

const { Header } = Layout;

const MyHeader: React.FC = (): ReactElement => {
    const [name, setName] = useState(`匿名用户`);
    const navigate = useNavigate();
    useEffect(() => {
        let username = localStorage.getItem('username');
        setName(username);
    }, []);

    const logout = () => {
        message.success('退出成功，即将返回登录页');
        localStorage.clear(); // 清除localStorage中的数据
        setTimeout(() => navigate('/login'), 1500);
    };

    const onMenuClick: MenuProps['onClick'] = (e) => {
        switch (e.key) {
            case '2':
                logout();
            default: {
                break;
            }
        }
    };

    const menu = (
        <Menu
            items={[
                {
                    key: '1',
                    label: '修改资料',
                },
                {
                    type: 'divider',
                },
                {
                    key: '2',
                    label: '退出登录',
                },
            ]}
            onClick={onMenuClick}
        />
    );

    return (
        <Header>
            <div className="title">
                {/* <div className="logo" /> */}
                <div className="name">藏文化元素基因库管理系统</div>
            </div>
            <Dropdown overlay={menu}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {name}
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
        </Header>
    );
};

export default MyHeader;
