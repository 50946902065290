/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 11:56:54
 * @LastEditTime: 2022-09-07 10:00:00
 * @Description:
 */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { message, Pagination, PaginationProps, Table, Tag } from 'antd';

import './LibraryList.less';

import { LibraryListPaginateApi } from '../../request/api';
import { IRes } from '../../../typings/interface';

interface DataType {
    key: React.Key;
    name: JSX.Element;
    tags: JSX.Element;
    original: JSX.Element;
    line: JSX.Element;
    vector: JSX.Element;
    created_At: string;
}

interface ILibrary {
    id: number;
    name: string;
    tags: string;
    original: string;
    line: string;
    vector: string;
    created_At: string;
}

const Title = (props: { name: string }) => {
    return <div className="libraryTitle">{props.name}</div>;
};

const Tags = (props: { tags: string[] }) => {
    return (
        <>
            {props.tags.map((tag) => {
                console.log(tag);
                return tag ? (
                    <Tag color="blue" key={tag}>
                        {tag}
                    </Tag>
                ) : (
                    ''
                );
            })}
        </>
    );
};

const columns: ColumnsType<DataType> = [
    {
        title: '名称',
        dataIndex: 'name',
        width: '6%',
    },
    {
        title: '标签',
        dataIndex: 'tags',
        width: '16%',
    },
    {
        title: '原始图片',
        dataIndex: 'original',
        width: '22%',
    },
    {
        title: '矢量图片',
        dataIndex: 'line',
        width: '22%',
    },
    {
        title: '彩色矢量图',
        dataIndex: 'vector',
        width: '22%',
    },
    {
        title: '发布时间',
        dataIndex: 'created_At',
    },
];

const LibraryList: React.FC = (): ReactElement => {
    const [data, setData] = useState<DataType[]>([]);
    const [total, setTotal] = useState(1);
    const [current, setCurrent] = useState(1);
    const [take, setTake] = useState(10);
    let init = true;

    const getLibraryList = (pageNumber: number, take: number) => {
        LibraryListPaginateApi({
            skip: (pageNumber - 1) * take,
            take,
        }).then((res: IRes) => {
            // console.log(res);
            if (res.errCode === 0) {
                message.success(res.message, 0.5);
            }
            let temparr: DataType[] = [];
            res.data.result.map((item: ILibrary) => {
                let obj = {
                    key: item.id,
                    name: <Title name={item.name} />,
                    tags: <Tags tags={item.tags.split(',')} />,
                    original: <a href={item.original}>{item.original}</a>,
                    line: <a href={item.line}>{item.line}</a>,
                    vector: <a href={item.vector}>{item.vector}</a>,
                    created_At: `${item.created_At.split('T')[0]} ${item.created_At.split('T')[1].split('.')[0]}`,
                };
                temparr.push(obj);
            });

            setData(temparr);
            setCurrent(pageNumber);
            setTotal(res.data.size);
        });
    };

    const onPageChange: PaginationProps['onChange'] = (pageNumber: number, pageSize: number) => {
        if (pageSize === take) getLibraryList(pageNumber, take);
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setTake(pageSize);
    };

    useEffect(() => {
        init = false;
        getLibraryList(1, take);
    }, []);

    useEffect(() => {
        if (init) getLibraryList(1, take);
    }, [take]);

    return (
        <div className="libraryList">
            {/* <Table columns={columns} dataSource={data} size="middle" pagination={{ hideOnSinglePage: true }} /> */}
            <Table columns={columns} dataSource={data} size="middle" pagination={false} />
            <Pagination
                className="pagination"
                showQuickJumper
                showTitle
                defaultCurrent={current}
                total={total}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onPageChange}
                showTotal={(total, range) => `第 ${range[0]}-${range[1]} 条 / 总共 ${total} 条`}
            />
        </div>
    );
};

export default LibraryList;
