/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 18:42:35
 * @LastEditTime: 2022-09-08 15:29:31
 * @Description:
 */
import type { ProColumns } from '@ant-design/pro-components';
import { EditableProTable } from '@ant-design/pro-components';
import { message } from 'antd';
import React, { ReactElement, useEffect, useState } from 'react';
import { UserListApi, UserRoleApi } from '../../request/api';
import { IRes } from '../../../typings/interface';

interface IUser {
    id: number;
    username: string;
    player: string;
    created_At: string;
    login_Latest: string;
}

type DataSourceType = {
    id: React.Key;
    username?: string;
    player?: string;
    created_At?: string;
    login_Latest?: string;
};

const UserList: React.FC = (): ReactElement => {
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
    const [dataSource, setDataSource] = useState<DataSourceType[]>([]);

    const getUserList = () => {
        UserListApi().then((res: IRes) => {
            console.log(res);
            if (res.errCode === 0) {
                message.success(res.message, 1.5);
            }
            let temparr: DataSourceType[] = [];
            res.data.result.map((item: IUser) => {
                let obj = {
                    id: item.id,
                    username: item.username,
                    player: item.player,
                    created_At: item.created_At,
                    login_Latest: item.login_Latest,
                };
                temparr.push(obj);
            });
            setDataSource(temparr);
        });
    };

    useEffect(() => {
        getUserList();
    }, []);

    const columns: ProColumns<DataSourceType>[] = [
        {
            title: '用户名',
            dataIndex: 'username',
            editable: false,
            width: '20%',
        },
        {
            title: '权限',
            key: 'player',
            dataIndex: 'player',
            valueType: 'select',
            valueEnum: {
                admin: { text: 'admin', status: 'Success' },
                editor: {
                    text: 'editor',
                    status: 'Warning',
                },
                normal: {
                    text: 'normal',
                    status: 'Default',
                },
            },
            width: '20%',
        },
        {
            title: '创建时间',
            dataIndex: 'created_At',
            editable: false,
            width: '20%',
        },

        {
            title: '最后登录',
            dataIndex: 'login_Latest',
            editable: false,
            width: '20%',
        },
        {
            title: '操作',
            valueType: 'option',
            render: (text: any, record: any, _: any, action: any) => [
                <a
                    key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.id);
                    }}
                >
                    编辑
                </a>,
                <a
                    key="delete"
                    onClick={() => {
                        setDataSource(dataSource.filter((item) => item.id !== record.id));
                    }}
                >
                    删除
                </a>,
            ],
        },
    ];

    return (
        <div className="userList">
            <EditableProTable<DataSourceType>
                rowKey="id"
                maxLength={10}
                recordCreatorProps={
                    false
                    // {position: 'top',record: () => ({ id: (Math.random() * 1000000).toFixed(0) }),}
                }
                loading={false}
                columns={columns}
                value={dataSource}
                onChange={setDataSource}
                editable={{
                    type: 'multiple',
                    editableKeys,
                    onSave: async (rowKey: any, data: any, row: any) => {
                        console.log(rowKey, data, row);
                        const obj = {
                            username: data.username,
                            player: data.player,
                        };
                        UserRoleApi(obj).then((res: IRes) => {
                            console.log(res);
                            if (res.errCode == 0) {
                                message.success(res.message);
                            } else {
                                message.error(res.message);
                            }
                        });
                    },
                    onChange: setEditableRowKeys,
                }}
                pagination={{
                    defaultCurrent: 1,
                }}
            />
        </div>
    );
};

export default UserList;
