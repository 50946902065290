/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 18:42:35
 * @LastEditTime: 2022-09-07 10:10:38
 * @Description:
 */
import type { ActionType, ProColumns } from '@ant-design/pro-components';
import { EditableProTable } from '@ant-design/pro-components';
import { Form, Input, InputRef, message, PaginationProps, Space, Tag } from 'antd';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { LibraryListPaginateApi, updateLibraryApi } from '../../request/api';
import { IRes } from '../../../typings/interface';
import './LibraryManage.less';

interface ILibrary {
    id: number;
    name: string;
    tags: string;
    original: string;
    line: string;
    vector: string;
    created_At: string;
}

type DataSourceType = {
    id: React.Key;
    name: JSX.Element;
    tags: Tag[];
    original: JSX.Element;
    line: JSX.Element;
    vector: JSX.Element;
    created_At: string;
};

type Tag = {
    key: string;
    label: string;
};

const Title = (props: { name: string }) => {
    return <div className="libraryTitle">{props.name}</div>;
};

const TagList: React.FC<{
    value?: Tag[];
    onChange?: (value: Tag[]) => void;
}> = ({ value, onChange }) => {
    const ref = useRef<InputRef | null>(null);
    const [newTags, setNewTags] = useState<Tag[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleInputConfirm = () => {
        let tempsTags = [...(value || [])];
        if (inputValue && tempsTags.filter((tag) => tag.label === inputValue).length === 0) {
            tempsTags = [...tempsTags, { key: `new-${tempsTags.length}`, label: inputValue }];
        }
        onChange?.(tempsTags);
        setNewTags([]);
        setInputValue('');
    };

    const handleClose = (removedTag: string) => {
        const newTags = value.filter((tag) => tag.key !== removedTag);
        console.log(newTags);
        onChange?.(newTags);
    };

    return (
        <Space>
            {(value || []).concat(newTags).map((item) => {
                return item.label ? (
                    <Tag
                        key={item.key}
                        color="blue"
                        closable
                        onClose={(e) => {
                            e.preventDefault();
                            handleClose(item.key);
                        }}
                    >
                        {item.label}
                    </Tag>
                ) : (
                    <div key="keynone"></div>
                );
            })}
            <Input
                key="input"
                ref={ref}
                type="text"
                size="small"
                style={{ width: 78 }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
            />
        </Space>
    );
};

const LibraryManage: React.FC = (): ReactElement => {
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
    const [dataSource, setDataSource] = useState<DataSourceType[]>([]);
    const [take, setTake] = useState(10);
    const [total, setTotal] = useState(1);
    const [current, setCurrent] = useState(1);
    let init = true;
    const actionRef = useRef<ActionType>();
    const [form] = Form.useForm();

    const getLibraryList = (pageNumber: number, take: number) => {
        LibraryListPaginateApi({
            skip: (pageNumber - 1) * take,
            take,
        }).then((res: IRes) => {
            // console.log(res);
            if (res.errCode === 0) {
                message.success(res.message, 0.5);
            }
            let temparr: DataSourceType[] = [];
            res.data.result.map((item: ILibrary) => {
                let tags = item.tags.split(',');
                let tagArray: Tag[] = [];
                tags.map((tag: string) => {
                    let o = { key: tag, label: tag };
                    tagArray.push(o);
                });
                let obj: DataSourceType = {
                    id: item.id,
                    name: <Title name={item.name} />,
                    tags: tagArray,
                    original: <a href={item.original}>{item.original}</a>,
                    line: <a href={item.line}>{item.line}</a>,
                    vector: <a href={item.vector}>{item.vector}</a>,
                    created_At: `${item.created_At.split('T')[0]} ${item.created_At.split('T')[1].split('.')[0]}`,
                };
                temparr.push(obj);
            });
            setDataSource(temparr);
            setCurrent(pageNumber);
            setTotal(res.data.size);
        });
    };

    const onPageChange: PaginationProps['onChange'] = (pageNumber: number, pageSize: number) => {
        if (pageSize === take) getLibraryList(pageNumber, take);
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setTake(pageSize);
    };

    useEffect(() => {
        init = false;
        getLibraryList(1, take);
    }, []);

    useEffect(() => {
        if (init) getLibraryList(1, take);
    }, [take]);

    const columns: ProColumns<DataSourceType>[] = [
        {
            title: '名称',
            dataIndex: 'name',
            width: '6%',
            editable: false,
        },
        {
            title: '标签',
            dataIndex: 'tags',
            width: '16%',
            renderFormItem: (_, { isEditable }) => {
                return isEditable ? <TagList /> : <Input />;
            },
            render: (_, row) =>
                row?.tags?.map((item) => {
                    return item.label ? (
                        <Tag key={item.key} color="blue">
                            {item.label}
                        </Tag>
                    ) : (
                        ''
                    );
                }),
        },
        {
            title: '原始图片',
            dataIndex: 'original',
            width: '20%',
            editable: false,
        },
        {
            title: '矢量图片',
            dataIndex: 'line',
            width: '20%',
            editable: false,
        },
        {
            title: '彩色矢量图',
            dataIndex: 'vector',
            width: '20%',
            editable: false,
        },
        {
            title: '发布时间',
            dataIndex: 'created_At',
            width: '12%',
            editable: false,
        },
        {
            title: '操作',
            valueType: 'option',
            render: (text: any, record: any, _: any, action: any) => [
                <a
                    key="editable"
                    onClick={() => {
                        action?.startEditable?.(record.id);
                    }}
                >
                    编辑
                </a>,
            ],
        },
    ];

    const tagsToArray = (tags: Tag[]) => {
        let tagsArray: string[] = [];
        tags.map((tag) => {
            tagsArray.push(tag.label);
        });
        return tagsArray.toString();
    };

    return (
        <div className="libraryList">
            <EditableProTable<DataSourceType>
                rowKey="id"
                maxLength={10}
                recordCreatorProps={false}
                loading={false}
                columns={columns}
                value={dataSource}
                actionRef={actionRef}
                onChange={setDataSource}
                editable={{
                    form,
                    editableKeys,
                    onSave: async (rowKey: any, data: any, row: any) => {
                        console.log(rowKey, data, row);
                        let tag =
                            tagsToArray(data.tags)[0] == ','
                                ? tagsToArray(data.tags).substring(1)
                                : tagsToArray(data.tags);
                        updateLibraryApi({
                            id: data.id,
                            tags: tag,
                        }).then((res: IRes) => {
                            console.log(res);
                            if (res.errCode == 0) {
                                message.success(res.message);
                            } else {
                                message.error(res.message);
                            }
                        });
                    },
                    onChange: setEditableRowKeys,
                    actionRender: (row, config, dom) => [dom.save, dom.cancel],
                }}
                pagination={{
                    defaultCurrent: current,
                    total: total,
                    pageSize: take,
                    onChange: onPageChange,
                    onShowSizeChange: onShowSizeChange,
                }}
            />
        </div>
    );
};

export default LibraryManage;
