/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 11:56:53
 * @LastEditTime: 2022-09-02 11:22:55
 * @Description:
 */
import React, { ReactElement, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumb, Layout, Menu, MenuProps } from 'antd';
import {
    DatabaseOutlined,
    FormOutlined,
    CloudDownloadOutlined,
    CommentOutlined,
    SolutionOutlined,
    UserOutlined,
    SettingOutlined,
} from '@ant-design/icons';
// 组件
const { Content, Sider } = Layout;
import MyHeader from './components/header';
import MyFooter from './components/footer';
// css
import './App.less';
import { UserInfoApi } from './request/api';
import { IRes } from '../typings/interface';
// 静态资源

interface IState {
    username: string;
    player: string;
    avatar: string;
}

const player = localStorage.getItem('player');

const App: React.FC = (props: IState): ReactElement => {
    const [current, setCurrent] = useState('');
    const [bread, setBread] = useState('');
    const location = useLocation();
    const navigate = useNavigate();

    UserInfoApi().then((res: IRes) => {
        if (res.errCode == 0) {
            localStorage.setItem('username', res.data.username);
            localStorage.setItem('tk-auth-token', res.data['tk-auth-token']);
            localStorage.setItem('avatar', res.data.avatar);
            localStorage.setItem('log intype', 'true');
        } else {
            localStorage.setItem('username', null);
            localStorage.setItem('tk-auth-token', null);
            localStorage.setItem('avatar', null);
            localStorage.setItem('logintype', 'false');
            navigate('/login');
        }
    });

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/librarylist');
        }
        switch (location.pathname) {
            case '/librarylist':
                setCurrent('item-1');
                setBread('查看数据库');
                break;
            case '/userlist':
                setCurrent('sub-item-1');
                setBread('用户管理');
                break;
            case '/epsapplylist':
                setCurrent('sub-item-2');
                setBread('eps下载申请');
                break;
            case '/contactlist':
                setCurrent('sub-item-3');
                setBread('消息管理');
                break;
            case '/courselist':
                setCurrent('sub-item-4');
                setBread('教程管理');
                break;
            case '/courseedit':
                setCurrent('sub-item-5');
                setBread('教程编辑');
                break;
            case '/librarymanage':
                setCurrent('sub-item-6');
                setBread('数据库管理');
                break;
            default:
                setCurrent('');
                break;
        }
        if (location.pathname.includes('/courseedit')) {
            setCurrent('sub-item-5');
        }
    }, [location.pathname]);

    const onClick: MenuProps['onClick'] = (e: any) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const items = [
        {
            label: <Link to={'/librarylist'}>查看数据库</Link>,
            key: 'item-1',
            icon: <DatabaseOutlined />,
        },
        {
            label: '修改资料',
            key: 'item-2',
            disabled: true,
            icon: <SettingOutlined />,
        },
        {
            label: '管理员',
            key: 'item-3',
            icon: <UserOutlined />,
            disabled: player == 'normal' ? true : false,
            children: [
                {
                    label: <Link to="/userlist">用户管理</Link>,
                    key: 'sub-item-1',
                    disabled: player == 'admin' ? false : true,
                    icon: <SolutionOutlined />,
                },
                {
                    label: <Link to="/epsapplylist">eps下载申请</Link>,
                    key: 'sub-item-2',
                    disabled: player == 'admin' ? false : true,
                    icon: <CloudDownloadOutlined />,
                },
                {
                    label: <Link to="/contactlist">消息管理</Link>,
                    key: 'sub-item-3',
                    disabled: player == 'admin' ? false : true,
                    icon: <CommentOutlined />,
                },
                {
                    label: <Link to="/courselist">教程管理</Link>,
                    key: 'sub-item-4',
                    disabled: player == 'admin' ? false : true,
                    icon: <FormOutlined />,
                },
                {
                    label: <Link to="/courseedit">教程编辑</Link>,
                    key: 'sub-item-5',
                    disabled: player == 'admin' || player == 'editor' ? false : true,
                    icon: <FormOutlined />,
                },
                {
                    label: <Link to="/librarymanage">数据库管理</Link>,
                    key: 'sub-item-6',
                    disabled: player == 'admin' ? false : true,
                    icon: <DatabaseOutlined />,
                },
            ],
        },
    ];

    return (
        <div className="appComponent">
            <MyHeader key={props.username} />
            <Layout className="container">
                <Layout>
                    <Sider width={200}>
                        <Menu
                            mode="inline"
                            theme="dark"
                            // defaultSelectedKeys={['item-1']}
                            onClick={onClick}
                            selectedKeys={[current]}
                            defaultOpenKeys={['item-3']}
                            style={{ height: '100%', borderRight: 0 }}
                            items={items}
                        />
                    </Sider>
                    <Layout style={{ padding: '0 24px 24px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }}>
                            <Breadcrumb.Item>
                                <Link to="/">首页</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{bread}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Content className="content">
                            <Outlet />
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
            <MyFooter />
        </div>
    );
};

// react redux
const mapStateToProps = (state: IState) => {
    return {
        username: state.username,
        avatar: state.avatar,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        // 修改react-redux的用户名
        changeUsername<T>(value: T): void {
            dispatch({ type: 'ChangeUsername', value });
        },
        // 修改react-redux的密码
        changePlayer<T>(value: T): void {
            dispatch({ type: 'ChangePlayer', value });
        },
        // 修改react-redux的头像
        changeAvatar<T>(value: T): void {
            dispatch({ type: 'ChangeAvatar', value });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
