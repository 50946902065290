/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 18:44:56
 * @LastEditTime: 2022-08-19 18:22:11
 * @Description:
 */
import React, { ReactElement } from 'react';
import MyEditor from '../../components/Editor';

const CourseEdit: React.FC = (): ReactElement => {
    return (
        <div>
            <MyEditor />
        </div>
    );
};

export default CourseEdit;
