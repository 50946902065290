/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-19 14:13:46
 * @LastEditTime: 2022-08-26 09:51:45
 * @Description:
 */
/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-19 14:13:46
 * @LastEditTime: 2022-08-25 10:19:02
 * @Description:
 */
import React, { useState, useEffect } from 'react';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor';
import { Button, Form, Input, message, PageHeader } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import { useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import './index.less';
import { addArticleApi, articleImageApi, editArticleApi, getArticleByIdApi } from '../../request/api';
import { IRes } from '../../../typings/interface';

function MyEditor() {
    // editor 实例
    const [editor, setEditor] = useState<IDomEditor | null>(null);

    // 编辑器内容
    const [html, setHtml] = useState('');
    const [title, setTitle] = useState('');

    // 预览
    const [edit, setEdit] = useState('editor-content');
    const [prev, setprev] = useState('editor-content-view-hide');
    const [prevBtn, setprevBtn] = useState('预览');

    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {};

    // 编辑器配置
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入内容...',
        MENU_CONF: {},
    };

    editorConfig.MENU_CONF['uploadImage'] = {
        server: articleImageApi,
        fieldName: 'file',
    };

    const params = useParams();
    const id = Number(params['*']);

    useEffect(() => {
        if (id != undefined) {
            getArticleByIdApi({ id: id }).then((res: IRes) => {
                console.log(id);
                if (res.errCode === 0) {
                    setTitle(res.data.title);
                    setHtml(res.data.content);
                }
            });
        }
        // 及时销毁 editor ，重要！.
        return () => {
            if (editor == null) return;
            editor.destroy();
            setEditor(null);
        };
    }, [editor]);

    const location = useLocation();
    const [form] = Form.useForm();

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
    };

    const preview = () => {
        if (edit == 'editor-content') {
            setEdit('editor-content-hide');
            setprev('editor-content-view');
            setprevBtn('编辑');
        } else {
            setEdit('editor-content');
            setprev('editor-content-view-hide');
            setprevBtn('预览');
        }
        // document.getElementById('editor-content-view').innerHTML = html;
        // editor.dangerouslyInsertHtml(html);
    };

    const onFinish = () => {
        form.validateFields(['title'])
            .then(() => {
                // 编辑文章
                if (id) {
                    const obj = {
                        id: id,
                        title: title,
                        content: html,
                    };
                    editArticleApi(obj).then((res: IRes) => {
                        console.log(res);
                        if (res.errCode == 0) {
                            message.success(res.message);
                        }
                    });
                } else {
                    // 新增文章
                    const obj = {
                        title: title,
                        content: html,
                    };
                    addArticleApi(obj).then((res: IRes) => {
                        console.log(res);
                        if (res.errCode == 0) {
                            message.success(res.message);
                        }
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="editor">
            <PageHeader
                style={{ padding: 0, marginBottom: '20px' }}
                ghost={false}
                onBack={() => null}
                backIcon={location.pathname == '/courseedit' ? false : <LeftOutlined />}
                title="文章编辑"
                subTitle={`当前日期：${moment().format('YYYY-MM-DD HH:mm:ss')}`}
                extra={[
                    <Form
                        key="form"
                        name="basic"
                        layout="inline"
                        form={form}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                        onFinish={onFinish}
                    >
                        <Form.Item>
                            <Button key="1" type="primary" onClick={preview}>
                                {prevBtn}
                            </Button>
                        </Form.Item>
                        <Form.Item label="标题">
                            <Input placeholder="未命名" name="title" value={title} onChange={onInputChange} />
                        </Form.Item>
                        <Form.Item>
                            <Button key="2" type="primary" htmlType="submit">
                                提交文章
                            </Button>
                        </Form.Item>
                    </Form>,
                ]}
            ></PageHeader>
            <div className={edit} style={{ border: '1px solid #ccc', zIndex: 100 }}>
                <Toolbar
                    editor={editor}
                    defaultConfig={toolbarConfig}
                    mode="default"
                    style={{ borderBottom: '1px solid #ccc' }}
                />
                <Editor
                    defaultConfig={editorConfig}
                    value={html}
                    onCreated={setEditor}
                    onChange={(editor) => setHtml(editor.getHtml())}
                    mode="default"
                    style={{ height: '540px', overflowY: 'hidden' }}
                />
            </div>
            {/* <div style={{ marginTop: '15px' }}>{html}</div> */}
            <div className={prev}>
                <div id="editor-content-view" dangerouslySetInnerHTML={{ __html: html }}></div>
            </div>
        </div>
    );
}

export default MyEditor;
