/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import { Button, Form, Input, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import { RegisterApi } from './request/api';
import MyFooter from './components/footer';

import './Register.less';
import background from './assets/pictures/bg.jpg';
import { Header } from 'antd/lib/layout/layout';
import { IRes } from '../typings/interface';

interface IRegisterParams {
    username: string | number;
    password: string | number;
    password1: string | number;
}

const Register: React.FC = (): ReactElement => {
    // const [form] = Form.useForm<{ user: string; password: string }>();
    const navigate = useNavigate();
    const onFinish = (values: IRegisterParams) => {
        console.log(values);
        RegisterApi(values).then((res: IRes) => {
            if (res.errCode == 0) {
                // 提示成功
                message.success(res.message, 1.5);

                // 跳转路径
                setTimeout(() => {
                    navigate('/login');
                }, 1500);
            } else {
                message.error(res.message, 1.5);
            }
        });
    };

    const onFinishFailed = (errorInfo: unknown) => {
        console.log('Failed:', errorInfo);
    };

    const style: React.CSSProperties = {
        backgroundImage: `url(${background})`,
    };

    return (
        <div className="registerBody" style={style}>
            <div className="registerBox">
                <Form
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    size="large"
                >
                    <Form.Item name="username" rules={[{ required: true, message: '请输入用户名!' }]}>
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入用户名：" />
                    </Form.Item>

                    <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="请输入密码："
                        />
                    </Form.Item>

                    <Form.Item name="password1" rules={[{ required: true, message: '再次输入密码!' }]}>
                        <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            placeholder="请输入密码："
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            注册
                        </Button>
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Link to="/login">已有账号？立即登录</Link>
                    </Form.Item>
                </Form>
            </div>
            <Header>
                <div className="title">
                    {/* <div className="logo" /> */}
                    <div className="name">藏文化元素基因库</div>
                </div>
            </Header>
            <div className="loginContainer"></div>
            <MyFooter />
        </div>
    );
};

export default Register;
