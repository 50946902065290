/*
 * @Author: 992526373@qq.com
 * @Date: 2022-08-18 18:41:27
 * @LastEditTime: 2022-08-31 10:55:43
 * @Description:
 */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import type { ColumnsType } from 'antd/es/table';
import { Button, message, Pagination, PaginationProps, Table } from 'antd';

import './EpsApplyList.less';

import { EpsApplyListPaginateApi, deleteEpsApplyApi } from '../../request/api';
import { IRes } from '../../../typings/interface';

interface DataType {
    key: React.Key;
    name: string;
    phone: string;
    email: string;
    content: string;
    library: string;
    created_At: string;
    action: JSX.Element;
}

interface IEpsApply {
    id: number;
    name: string;
    phone: string;
    email: string;
    content: string;
    library: string;
    created_At: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: '联系人',
        dataIndex: 'name',
        width: '6%',
    },
    {
        title: '电话',
        dataIndex: 'phone',
        width: '8%',
    },
    {
        title: '邮箱',
        dataIndex: 'email',
        width: '14%',
    },
    {
        title: '内容',
        dataIndex: 'content',
        width: '30%',
    },
    {
        title: '请求eps文件',
        dataIndex: 'library',
        width: '10%',
    },
    {
        title: '提交时间',
        dataIndex: 'created_At',
        width: '14%',
    },
    {
        title: '操作',
        dataIndex: 'action',
    },
];

const ActionBtn = (props: {
    id: number;
    current: number;
    getEpsApplyList: (pageNumber: number, take: number) => void;
}) => {
    const onDelete = () => {
        deleteEpsApplyApi({ id: props.id }).then((res: IRes) => {
            if (res.errCode == 0) {
                message.success(res.message);
                console.log(props.current);
                props.getEpsApplyList(props.current, 10);
            } else {
                message.error(res.message);
            }
        });
        return;
    };

    return (
        <>
            <Button className="button" type="primary" danger onClick={onDelete}>
                删除
            </Button>
        </>
    );
};

const EpsApplyList: React.FC = (): ReactElement => {
    const [data, setData] = useState<DataType[]>([]);
    const [total, setTotal] = useState(1);
    const [current, setCurrent] = useState(1);
    const [take, setTake] = useState(10);
    let init = true;

    const getEpsApplyList = (pageNumber: number, take: number) => {
        EpsApplyListPaginateApi({
            skip: (pageNumber - 1) * take,
            take,
        }).then((res: IRes) => {
            // console.log(res);
            if (res.errCode === 0) {
                message.success(res.message, 0.5);
            }
            let temparr: DataType[] = [];
            res.data.result.map((item: IEpsApply) => {
                let obj = {
                    key: item.id,
                    name: item.name,
                    phone: item.phone,
                    email: item.email,
                    content: item.content,
                    library: item.library,
                    created_At: `${item.created_At.split('T')[0]} ${item.created_At.split('T')[1].split('.')[0]}`,
                    action: <ActionBtn id={item.id} current={pageNumber} getEpsApplyList={getEpsApplyList} />,
                };
                temparr.push(obj);
            });
            setData(temparr);
            setCurrent(pageNumber);
            setTotal(res.data.size);
        });
    };

    const onPageChange: PaginationProps['onChange'] = (pageNumber: number, pageSize: number) => {
        if (pageSize === take) getEpsApplyList(pageNumber, take);
    };

    const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
        setTake(pageSize);
    };

    useEffect(() => {
        getEpsApplyList(1, take);
        init = false;
    }, []);

    useEffect(() => {
        if (init) getEpsApplyList(1, take);
    }, [take]);

    return (
        <div className="epsApplyList">
            {/* <Table columns={columns} dataSource={data} size="middle" pagination={{ hideOnSinglePage: true }} /> */}
            <Table columns={columns} dataSource={data} size="middle" pagination={false} />
            <Pagination
                className="pagination"
                showQuickJumper
                showTitle
                defaultCurrent={current}
                total={total}
                showSizeChanger
                onShowSizeChange={onShowSizeChange}
                onChange={onPageChange}
                showTotal={(total, range) => `第 ${range[0]}-${range[1]} 条 / 总共 ${total} 条`}
            />
        </div>
    );
};

export default EpsApplyList;
